import React, {PropTypes} from 'react';
import Table from './Table';

class Context extends React.Component {
  getChildContext() {
    const {locale} = this.props;
    return {locale};
  }
  render() {
    return <Table {...this.props} />;
  }

  static childContextTypes = {
    locale: PropTypes.string.isRequired
  }

  static defaultProps = {
    locale: "es"
  }
}

export default Context;
