import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import ContentTable from "./ContentTable";
import initialize from "./store";
import { uniqueId, clone } from "lodash";
import { formatNumber } from "../util/format";

function normalizeCell(cell, column) {
  const numbers = ["currency", "number", "percentage", "progress"];
  if (!column || numbers.indexOf(column.type) < 0) {
    return cell;
  }
  return formatNumber(cell);
}

function transform(table, media) {
  const columns = table.descriptor && table.descriptor.columns;
  console.log(media);
  const origin = clone(table.data);
  let headings;
  const result = {
    rows: [],
    contents: {},
    documents: {}
  };
  origin.forEach((row, id) => {
    if (id === 0) headings = row;
    else {
      /* result.rows[id.toString()] = []; */
      const position =
        result.rows.push({
          id: id.toString(),
          row: []
        }) - 1;
      result.contents[id.toString()] = [];
      result.documents[id.toString()] = [];
      row.forEach((cell, index) => {
        if (!columns[index].isHidden) {
          if (columns[index].type === "documents") {
            result.documents[id.toString()].push({ index, value: cell });
          }
          if (
            columns[index].refolding <= media &&
            columns[index].type !== "documents"
          ) {
            result.contents[id.toString()].push({ index, value: cell });
          } else if (columns[index].type !== "documents") {
            result.rows[position].row.push({
              index,
              value: normalizeCell(cell, columns[index])
            });
          }
        }
      });
    }
  });
  return {
    ...result,
    columns,
    headings
  };
}

export default class App extends React.Component {
  componentWillMount = () => {
    const store = initialize(transform(this.props.table, this.props.media));
    this.setState({ store });
  };

  componentWillUnmount = () => {};

  render() {
    const { table, locale, filePath, selected } = this.props;
    const { store } = this.state;
    return (
      <Provider key={uniqueId()} store={store}>
        <ContentTable
          locale={locale}
          filePath={filePath}
          download={table}
          selected={selected}
        />
      </Provider>
    );
  }

  static propTypes = {
    filePath: PropTypes.string,
    table: PropTypes.object
  };
}
