import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { forOwn, uniq } from 'lodash';

const ColumnFilter = props => (
  <select className="column-filter" defaultValue="none" onChange={event => props.changeFilter(props.id, event.target.value)}>
    <option value="none">
      -
    </option>
    {props.filters[props.id].map((item, index) => <option key={index} value={item}>{item}</option>)}
  </select>
);

export default connect((state) => {
  const columns = {};
  const filters = {};
  forOwn(state.data.rows, (row) => {
    row.forEach((elem, index) => {
      if (!Array.isArray(columns[elem.index])) {
        columns[index] = [elem.value];
      } else {
        columns[index].push(elem.value);
      }
    });
  });
  forOwn(columns, (value, key) => { filters[key] = uniq(value); });
  return { filters };
}, dispatch => ({
  changeFilter: (id, filter) => dispatch({ type: 'FILTER_UPDATE', id, filter }),
}))(ColumnFilter);
