import { formatNumber } from '../util/format';


const operations = {
  sum: (accumulator, value) => (accumulator || 0) + formatNumber(value),
  max: (accumulator, value) => (accumulator >= formatNumber(value) ? accumulator : formatNumber(value)),
  min: (accumulator, value) => (accumulator <= formatNumber(value) ? accumulator : formatNumber(value)),
  first: (accumulator, value) => accumulator || formatNumber(value),
  count: accumulator => (accumulator || 0) + 1,
};

export default operations;
