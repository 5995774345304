import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import localize from "../util/localize";
import { forOwn, uniq, clone } from "lodash";
import { debounceSyntheticEvent as debounce } from "../util/debounce";

const ColumnGroup = (props, context) => {
  function changeHandler(event) {
    const newGroup = clone(props.groups);
    newGroup[props.id] = !props.groups[props.id];
    props.changeGrouped(newGroup);
  }

  return (
    <div className="column-grouper">
      <input
        id={`group-${props.id}`}
        checked={props.groups[props.id] || false}
        type="checkbox"
        onChange={debounce(changeHandler, 200, this)}
      />
      <label htmlFor={`group-${props.id}`}>
        {props.groups[props.id] || false
          ? localize({ es: "Desagrupar", ca: "Desagrupar" }, context.locale)
          : localize({ es: "Agrupar", ca: "Agrupar" }, context.locale)}
      </label>
    </div>
  );
};

ColumnGroup.contextTypes = {
  locale: PropTypes.string.isRequired
};

export default connect(
  state => ({ groups: state.groups }),
  dispatch => ({
    changeGrouped: group => dispatch({ type: "GROUPS_UPDATE", group })
  })
)(ColumnGroup);
