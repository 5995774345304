import React from "react";
import PropTypes from "prop-types";
import { flattenDeep, isEmpty, compact } from "lodash";
import { connect } from "react-redux";
import Row from "./Row";
import SubTable from "./SubTable";
import formatter from "../util/format";
import localize from "../util/localize";
import ScrollInAndOut from "../transitions/ScrollInAndOut";

class GroupRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.opened !== this.props.opened && this.props.opened) {
      this.setState({ loaded: true });
    }
  }

  getSubtable(rows) {
    const { columns, groups } = this.props;
    const indexes = [];
    rows[0].row.forEach((row, index) => {
      if (!groups[row.index]) {
        indexes.push(row.index);
      }
    });
    return indexes;
  }

  render() {
    const {
      columns,
      rows,
      id,
      headers,
      opened,
      contents,
      documents,
      isSelected
    } = this.props;
    const { event } = this.context;
    const data = rows[id];
    return (
      <div
        className={`grouped row ${opened ? "open" : ""} ${isSelected?"selected":""}`}
        onClick={e => {
          this.props.updateOpened(id);
        }}
      >
        <div className="cells" onClick={e => 
        event && event.onGroupSelect && event.onGroupSelect(data)
        }>
          <div
            className="cell icon"
            style={{
              order: -2
            }}
            onClick={event => {
              opened
                ? this.props.updateOpened(false)
                : this.props.updateOpened(id);
              event.stopPropagation();
            }}
          >
            {<i className={`fa fa-angle-${opened ? "down" : "right"}`}> </i>}
          </div>
          <div className="cell icon num">{`${
            Object.keys(data.rows).length
          } ${localize(
            { es: "elementos", ca: "elements" },
            this.context.locale
          )}`}</div>
          {data.row.map((value, index) => (
            <div
              data-group={this.props.groups[value.index] ? "yes" : "no"}
              key={index}
              data-metatype={
                columns && columns[value.index] && columns[value.index].type
              }
              data-label={localize(
                this.props.headers[index],
                this.context.locale
              )}
              className={`cell${
                columns && columns[value.index]
                  ? " " + columns[value.index].style
                  : ""
              }`}
            >
              {columns && value.index
                ? formatter(value.value, columns[value.index])
                : value.value}
            </div>
          ))}
        </div>
        <ScrollInAndOut in={opened} className="group">
          {this.state.loaded ? (
            <SubTable rows={data.rows} displays={this.getSubtable(data.rows)} />
          ) : null}
        </ScrollInAndOut>
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array.isRequired,
    content: PropTypes.array.isRequired,
    document: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  };

  static contextTypes = {
    event: PropTypes.object,
    filePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
  };
}

export default connect(state => ({
  columns: state.data.columns,
  contents: state.data.contents,
  documents: state.data.documents,
  rows: state.data.rows,
  result: state.data.result,
  headers: state.data.columns
    ? state.data.columns.map(item => item.title)
    : state.data.headings,
  groups: state.groups
}))(GroupRow);
