import React  from "react";
import PropTypes from "prop-types";
import TableTree from "./TableTree";
import localization from "../util/localize";
import { map, without, clone, cloneDeep, compact, isObject } from "lodash";
import RowDraw, { formatNumber } from "../util/RowDraw";
import getByCriteria from "../util/getByCriteria";
import { iconClass } from "../util/vars";
// require('metatype.css');

function exportToCsv(filename, rows) {
  var processRow = function(row) {
    var finalVal = "";
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? "" : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ",";
      finalVal += result;
    }
    return finalVal + "\n";
  };

  var csvFile = "";
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
class Table extends React.Component {
  constructor() {
    super();
    this.state = {
      sort: []
    };
  }

  nextPosition(position) {
    const positions = ["neutral", "asc", "desc"];
    const number =
      positions.indexOf(position) + 1 >= 3
        ? 0
        : positions.indexOf(position) + 1;
    return positions[number];
  }

  icon(type, criteria) {
    return `fa fa-sort-${type === "string" ? "alpha" : "numeric"}-${iconClass[
      criteria
    ]}`;
  }

  updateSort(index) {
    const sort = clone(this.state.sort);
    sort[index] = this.nextPosition(this.state.sort[index] || "neutral");
    this.setState({ sort });
  }

  render() {
    let tableData = this.props.table.data;
    let tableType = this.props.table.type;
    let descriptor = isObject(this.props.table.descriptor)
      ? cloneDeep(this.props.table.descriptor)
      : false;
    // if ((descriptor && descriptor.type === "none") || tableType === "none") return null;
    if (descriptor)
      descriptor.collapse = getByCriteria(descriptor, "isCollapsible", 0)[0];
    let format = text => {
      return isNaN(formatNumber(text)) ? text : formatNumber(text);
    };
    return (
      <div
        style={{
          overflowX: "auto"
        }}
      >
        <table className="collapsible-table">
          <thead>
            <tr>
              {(() => {
                if (
                  descriptor.type === "collapsible" ||
                  tableType === "collapsible"
                ) {
                  return <th />;
                }
              })()}
              {(() => {
                if (!!descriptor && descriptor.columns) {
                  return compact(
                    descriptor.columns.map((elem, ind) => {
                      if (elem.isHidden) return;
                      let amnt = tableData[0].length;
                      let sortable;
                      if (ind >= amnt) return;
                      if (descriptor.type === "collapsible" && ind == 0) return;
                      if (elem.isSortable) {
                        sortable = (
                          <i
                            className={`sort ${this.state.sort[ind]} ${::this
                              .icon(elem.type, this.state.sort[ind])}`}
                            onClick={() => this.updateSort(ind)}
                          />
                        );
                      }
                      return (
                        <th
                          colSpan={elem.type === "progress" ? 2 : 1}
                          data-bocadillo={elem.tooltip}
                          className="center aligned"
                          data-metatype={elem.type}
                          key={"th-" + ind}
                        >
                          <span>
                            {localization(elem.title, this.context.locale)}
                          </span>
                          {sortable}
                        </th>
                      );
                    })
                  );
                } else {
                  return without(
                    tableData[0].map((item, ind) => {
                      if (tableType === "collapsible" && ind == 0) return;
                      return <th key={"th-" + ind}>{item}</th>;
                    }),
                    undefined
                  );
                }
              })()}
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td colSpan="3">
                <a
                  className="download-button"
                  onClick={() => {
                    this::exportToCsv(
                      !!descriptor
                        ? localization(descriptor.title, this.context.locale) +
                          ".csv"
                        : this.props.table.title + ".csv",
                      tableData
                    );
                  }}
                >
                  <i className="fa fa-download" />
                  CSV
                </a>
              </td>
            </tr>
          </tfoot>
          {(() => {
            if (
              descriptor.type === "collapsible" ||
              tableType === "collapsible"
            ) {
              return (
                <TableTree
                  table={this.props.table}
                  descriptor={descriptor}
                  sort={this.state.sort}
                />
              );
            } else {
              let body = tableData.slice(1, tableData.length);
              let rows = body.map((row, rowIndex) => {
                let RowArray = RowDraw(row, rowIndex, descriptor);
                return <tr key={`row-${rowIndex}`}>{RowArray}</tr>;
              });
              return <tbody>{rows}</tbody>;
            }
          })()}
        </table>
      </div>
    );
  }
}

Table.contextTypes = {
  locale: PropTypes.string.isRequired
};
export default Table;
