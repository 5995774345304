import React from "react";
function formatNumber(value) {
  const number = /^[-]?\d+([,]\d*)?$/;
  const european = /^[-]?\d{1,3}([.]\d{3})*([,]\d+)?$/;
  const english = /^[-]?\d{1,3}([,]\d{3})*([.]\d+)?$/;
  let num = value;
  if (isNaN(value)) {
    if (number.test(value)) {
      num = value.replace(",", ".");
    } else if (european.test(value)) {
      num = value.replace(".", "").replace(",", ".");
    } else if (english.test(value)) {
      num = value.replace(",", "");
    } else {
      num = NaN;
    }
  }
  return parseFloat(num);
}

function formatter(value, format) {
  switch (format.type) {
    case "number":
      return (
        <span data-metatype={format.type} className={format.style}>
          {formatNumber(value).toLocaleString("es")}
        </span>
      );
    case "percentage":
      return (
        <span data-metatype={format.type} className={format.style}>
          {(formatNumber(value) / 100).toLocaleString("es", {
            style: "percent",
            minimumFractionDigits: 2
          })}
        </span>
      );
    case "currency":
      return (
        <span data-metatype={format.type} className={format.style}>
          {formatNumber(value).toLocaleString("es", {
            style: "currency",
            currency: "EUR",
            currencyDisplay: "symbol"
          })}
        </span>
      );
    case "link":
      return (
        <a
          data-metatype={format.type}
          className={format.style}
          href={value}
          onMouseDown={e => e.preventDefault()}
          target="blank_"
        >
          {value}
        </a>
      );
    case "iconlink":
      return (
        <a
          data-metatype={format.type}
          href={value}
          onMouseDown={e => e.preventDefault()}
          target="blank_"
        >
          <i className={`fa fa-${format.style}`} />
        </a>
      );
    case "node":
      return (
        <a
          data-metatype={format.type}
          className={format.style}
          href={`//node/${value}`}
        >
          {value}
        </a>
      );
    case "path":
      return (
        <a
          data-metatype={format.type}
          className={format.style}
          href={`//${value}`}
        >
          {value}
        </a>
      );
    case "date":
      return (
        <span data-metatype={format.type} className={format.style}>
          {new Date(value).toLocaleDateString("es")}
        </span>
      );
    default:
      return (
        <span data-metatype={format.type} className={format.style}>
          {value}
        </span>
      );
  }
}
export { formatNumber };
export default formatter;
