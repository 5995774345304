import React from "react";
import PropTypes from "prop-types";
import { flattenDeep, isEmpty, compact, reduce, find } from "lodash";
import { connect } from "react-redux";
import formatter from "../util/format";
import localize from "../util/localize";
import LinkPopup from "../components/LinkPopup";
import ScrollInAndOut from "../transitions/ScrollInAndOut";

class Row extends React.Component {
  render() {
    const { columns, row, opened /* identifiers */, isSelected } = this.props;
    const hasContent =
      !isEmpty(this.props.content) || !isEmpty(this.props.document);
    const isBig =
      reduce(this.props.content, (sum, elem) => sum + elem.value, "").length >
      250;
    const fileExtension = new RegExp("\\.(\\w{2,5})$");
    return (
      <div
        className={`row ${opened ? "open" : ""} ${
          isSelected ? "selected" : ""
        }`}
        onClick={() => {
          if (hasContent) this.props.updateOpened(this.props.id);
        }}
      >
        <div className="cells">
          {this.props.grouped ? (
            <div
              className="cell icon"
              data-group="yes"
              style={{
                order: -2
              }}
            />
          ) : null}
          <div
            className="cell icon"
            onClick={e => {
              if (hasContent) {
                opened
                  ? this.props.updateOpened(false)
                  : this.props.updateOpened(this.props.id);
              }
              e.stopPropagation();
            }}
          >
            {hasContent ? (
              <i className={`fa fa-angle-${opened ? "down" : "right"}`} />
            ) : null}
          </div>
          {row.map(item => (
            <div
              key={item.index}
              data-group={this.props.groups[item.index] ? "yes" : "no"}
              data-label={localize(
                this.props.headers[item.index],
                this.context.locale
              )}
              data-metatype={columns[item.index].type}
              className={"cell " + columns[item.index].style}
            >
              {columns
                ? formatter(item.value, columns[item.index])
                : item.value}
            </div>
          ))}
        </div>
        {hasContent ? (
          <ScrollInAndOut
            in={opened}
            className={`contents ${isBig ? "big" : ""}`}
          >
            {!isEmpty(this.props.content) ? (
              <div className="box container">
                {this.props.content.map(item => (
                  <div
                    key={item.index}
                    className={`content${item.value ? "" : " inactive"}`}
                  >
                    <span className="label">
                      {localize(
                        this.props.headers[item.index],
                        this.context.locale
                      )}
                    </span>
                    {columns
                      ? formatter(item.value, columns[item.index])
                      : item.value}
                  </div>
                ))}
              </div>
            ) : null}
            {!isEmpty(this.props.document) &&
            reduce(
              this.props.document,
              (test, doc) => {
                if (!isEmpty(doc.value)) {
                  return true;
                } else {
                  return test;
                }
              },
              false
            ) ? (
              <div className="documents container">
                <div className="title">
                  {localize(
                    {
                      ca: "Documents",
                      es: "Documentos"
                    },
                    this.context.locale
                  )}
                </div>
                <div className="box">
                  {compact(
                    flattenDeep(
                      this.props.document.map(item =>
                        item.value.split(",").map(
                          (doc, ind) =>
                            !doc ? (
                              undefined
                            ) : (
                              <div
                                key={`${item.index}-${ind}`}
                                className="document content"
                              >
                                {this.context.isDirectLink ? (
                                  <a
                                    onMouseDown={event => {
                                      event.preventDefault();
                                    }}
                                    href={`${this.context.filePath}/${
                                      fileExtension.test(doc)
                                        ? doc
                                        : doc + ".pdf"
                                    }`}
                                    target="_blank"
                                  >
                                    <span className="label">
                                      {localize(
                                        this.props.headers[item.index],
                                        this.context.locale
                                      )}
                                    </span>
                                    {doc}
                                  </a>
                                ) : (
                                  <LinkPopup
                                    name={doc}
                                    href={`${this.context.filePath}${
                                      fileExtension.test(doc)
                                        ? doc
                                        : doc + ".pdf"
                                    }`}
                                  />
                                )}
                              </div>
                            )
                        )
                      )
                    )
                  )}
                </div>
              </div>
            ) : null}
          </ScrollInAndOut>
        ) : null}
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array.isRequired,
    content: PropTypes.array.isRequired,
    document: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  };

  static contextTypes = {
    filePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
  };
}

export default connect(state => ({
  columns: state.data.columns,
  groups: state.groups,
  //identifiers: state.identifiers,
  grouped: reduce(state.groups, (test, value) => test || value)
}))(Row);
