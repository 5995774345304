import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import localize from "../util/localize";
import ColumnFilter from "../components/ColumnFilter";
import { iconClass } from "../util/vars";

class Header extends React.Component {
  nextPosition(position) {
    const positions = ["neutral", "asc", "desc"];
    const number =
      positions.indexOf(position) + 1 >= 3
        ? 0
        : positions.indexOf(position) + 1;
    return positions[number];
  }

  icon(type, criteria) {
    return `fa fa-sort-${type === "string" ? "alpha" : "numeric"}-${
      iconClass[criteria]
    }`;
  }

  render() {
    const headers = this.context.file
      ? this.props.headers.slice(1)
      : this.props.headers;
    return (
      <div className="header">
        {headers.map((item, index) => (
          <div
            key={index}
            data-metatype={(item && item.type) || ""}
            className={"cell " + item.style}
          >
            {item.isFilterable ? <ColumnFilter id={index} /> : null}
            {localize(item.title ? item.title : item, this.context.locale)}
            {item.isSortable ? (
              <i
                onClick={() =>
                  this.props.updateCriteria(
                    index,
                    this.nextPosition(this.props.criteria[index] || "neutral")
                  )
                }
                className={`sort ${this.props.criteria[index] ||
                  "neutral"} ${this.icon(
                  item.type,
                  this.props.criteria[index]
                )}`}
              />
            ) : null}
          </div>
        ))}
        {this.context.file ? <div className="cell icon" /> : null}
      </div>
    );
  }

  static contextTypes = {
    locale: PropTypes.string.isRequired,
    file: PropTypes.bool.isRequired
  };
}

export default connect(
  state => ({
    headers: state.data.columns
      ? state.data.rows[0].row.map(
          item => state.data.columns[item.index]
        )
      : state.data.headings,
    criteria: state.criteria
  }),
  dispatch => ({
    updateCriteria: (index, direction) =>
      dispatch({ type: "UPDATE_CRITERIA", index, direction })
  })
)(Header);
