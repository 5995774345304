import React from "react";
import PropTypes from "prop-types";
import { flattenDeep, isEmpty, compact, reduce, map } from "lodash";
import { connect } from "react-redux";
import formatter from "../util/format";
import localize from "../util/localize";
import LinkPopup from "../components/LinkPopup";
import ScrollInAndOut from "../transitions/ScrollInAndOut";

class Row extends React.Component {
  contain(content, columns) {
    const blocks = {};
    const general = [];
    const result = [];
    if (columns) {
      content.forEach(elem => {
        const column = columns[elem.index];
        if (column.block) {
          if (Array.isArray(blocks[column.block]))
            blocks[column.block].push(elem);
          else blocks[column.block] = [elem];
        } else {
          general.push(elem);
        }
      });
      if (general.length > 0) {
        result.push(
          <div className="box container">
            {general.map(item => (
              <div
                key={item.index}
                className={`content${item.value ? "" : " inactive"}`}
              >
                <span className="label">
                  {localize(
                    this.props.headers[item.index],
                    this.context.locale
                  )}
                </span>
                {columns
                  ? formatter(item.value, columns[item.index])
                  : item.value}
              </div>
            ))}
          </div>
        );
      }
      if (!isEmpty(blocks)) {
        result.push(
          map(blocks, (block, title) => (
            <div className="container block">
              <div className="title">{title}</div>
              <div className="box">
                {block.map(item => (
                  <div
                    key={item.index}
                    className={`content${item.value ? "" : " inactive"}`}
                  >
                    <span className="label">
                      {localize(
                        this.props.headers[item.index],
                        this.context.locale
                      )}
                    </span>
                    {columns
                      ? formatter(item.value, columns[item.index])
                      : item.value}
                  </div>
                ))}
              </div>
            </div>
          ))
        );
      }
      return result;
    } else {
      return (
        <div className="box container">
          {content.map(item => (
            <div
              key={item.index}
              className={`content${item.value ? "" : " inactive"}`}
            >
              <span className="label">
                {localize(this.props.headers[item.index], this.context.locale)}
              </span>
              {columns
                ? formatter(item.value, columns[item.index])
                : item.value}
            </div>
          ))}
        </div>
      );
    }
  }

  render() {
    const { columns, opened, id, row, isSelected } = this.props;
    const { event } = this.context;
    const hasContent =
      !isEmpty(this.props.content) || !isEmpty(this.props.document);
    const isBig =
      reduce(this.props.content, (sum, elem) => sum + elem.value, "").length >
      1200;
    const fileExtension = new RegExp("\\.(\\w{2,5})$");
    return (
      <div
        className={`row ${opened ? "open" : ""}${
          isSelected ? " selected" : ""
        }`}
        onClick={() => {
          event && event.onClick && event.onClick({ id, row });
          if (hasContent) this.props.updateOpened();
        }}
      >
        <div className="cells">
          <div
            className="cell icon arrow"
            onClick={e => {
              if (hasContent) {
                opened
                  ? this.props.updateOpened(true)
                  : this.props.updateOpened();
              }
              e.stopPropagation();
            }}
          >
            {hasContent ? (
              <i className={`fa fa-angle-${opened ? "down" : "right"}`} />
            ) : null}
          </div>
          {this.props.row.map((item, index) => (
            <div
              key={item.index}
              data-label={localize(
                this.props.headers[item.index],
                this.context.locale
              )}
              data-metatype={columns[item.index].type}
              className={
                "cell " +
                columns[item.index].style +
                (index === 0 ? " first" : "")
              }
            >
              {columns
                ? formatter(item.value, columns[item.index])
                : item.value}
            </div>
          ))}
        </div>
        {hasContent ? (
          <ScrollInAndOut
            in={opened}
            className={`contents ${isBig ? "big" : ""}`}
          >
            {!isEmpty(this.props.content)
              ? ::this.contain(this.props.content, columns)
              : null}
            {!isEmpty(this.props.document) &&
            reduce(
              this.props.document,
              (test, doc) => {
                if (!isEmpty(doc.value)) {
                  return true;
                } else {
                  return test;
                }
              },
              false
            ) ? (
              <div className="documents container">
                <div className="title">
                  {localize(
                    {
                      ca: "Documents",
                      es: "Documentos"
                    },
                    this.context.locale
                  )}
                </div>
                <div className="box">
                  {compact(
                    flattenDeep(
                      this.props.document.map(item =>
                        item.value.split(",").map(
                          (doc, ind) =>
                            !doc ? (
                              undefined
                            ) : (
                              <div
                                key={`${item.index}-${ind}`}
                                className="document content"
                              >
                                {doc &&
                                  (this.context.isDirectLink ? (
                                    <a
                                      onMouseDown={event => {
                                        event.preventDefault();
                                      }}
                                      href={`${this.context.filePath}/${
                                        fileExtension.test(doc)
                                          ? doc
                                          : doc + ".pdf"
                                      }`}
                                      target="_blank"
                                    >
                                      <span className="label">
                                        {localize(
                                          this.props.headers[item.index],
                                          this.context.locale
                                        )}
                                      </span>
                                      {doc}
                                    </a>
                                  ) : (
                                    <LinkPopup
                                      name={doc}
                                      href={`${this.context.filePath}${
                                        fileExtension.test(doc)
                                          ? doc
                                          : doc + ".pdf"
                                      }`}
                                    />
                                  ))}
                              </div>
                            )
                        )
                      )
                    )
                  )}
                </div>
              </div>
            ) : null}
          </ScrollInAndOut>
        ) : null}
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array,
    content: PropTypes.array,
    document: PropTypes.array,
    columns: PropTypes.array
  };

  static contextTypes = {
    filePath: PropTypes.string,
    locale: PropTypes.string,
    isDirectLink: PropTypes.bool,
    event: PropTypes.object
  };
}

export default connect(state => ({ columns: state.data.columns }))(Row);
