/* eslint-env browser */
import React from "react";
import { render } from "react-dom";
import App from "./App";
import "../css/contentTable.css";

const qs = document.querySelector.bind(document);

function tableInit(table, selector) {
  const lang = table.locale;
  // let lang = /\w{2}/.exec(Drupal.settings.pathPrefix)[0];
  // if (lang === 'va') lang = 'ca';
  const filePath = table.basePath;
  const sqlBasepath = table.sqlBasepath;
  render(
    <App
      table={table}
      locale={lang}
      filePath={filePath}
      sqlBasepath={sqlBasepath}
      isDirectLink
    />,
    qs(selector)
  );
}

window.addEventListener(
  "load",
  function load() {
    window.removeEventListener("load", load, false); // remove listener, no longer needed
    try {
      Drupal.settings.tablas_cliente &&
        Drupal.settings.tablas_cliente.forEach(elem => {
          try {
            if (elem.descriptor) {
              elem.descriptor = JSON.parse(elem.descriptor);
            }
            tableInit(elem, `#tablefield-wrapper-${elem.id}`);
          } catch (err) {
            console.error("Error renderizando tabla", elem, err);
          }
        });
    } catch (err) {
      console.error("Error renderizando tablas", err);
    }
  },
  false
);
