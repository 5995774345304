import { without, cloneDeep, isNil,isObject, isEmpty } from "lodash";

function parseDescriptor(table, locale = "ca") {
  if (Array.isArray(table)) {
    return {
      data: table
    };
  }
  if (!isObject(table.descriptor)) {
    return table;
  }
  const result = cloneDeep(table);
  describe(result.data, result.descriptor, locale);
  const collapsible =
    getByCriteria(result.descriptor, "isCollapsible", false)[0] !== false;
  const grouped =
    getByCriteria(result.descriptor, "isGroupable", false)[0] !== false;
  const content =
    !isNaN(getByCriteria(result.descriptor, "refolding", NaN)[0]) ||
    !!getByValue(result.descriptor, "type", "documents", false)[0];
  const files =
    getByValue(result.descriptor, "type", "pdfs", false)[0] !== false;
  result.descriptor.type = collapsible
    ? "collapsible"
    : grouped ? "grouped" : content ? "content" : files ? "files" : "";
  // result.descriptor.type = collapsible ? 'collapsible' : content ? 'content' : files ? 'files' : '';
  return result;
}

function describe(data, descriptor, locale) {
  // if (descriptor.defaultColumn) {
  descriptor.columns = data[0].map((header, index) => {
    if (descriptor.columns[index]) {
      const column = descriptor.columns[index];
      if (!column.title || isEmpty(column.title)) {
        column.title = { und: header };
      }
      return column;
    } else if (descriptor.defaultColumn) {
      const column = cloneDeep(descriptor.defaultColumn);
      column.title = {
        und: header
      };
      return column;
    }
  });
  // }
  descriptor.columns = descriptor.columns.map((column, index) => {
    if (!column.isHidden && !!column.locale) {
      column.isHidden = column.locale !== locale;
    }
    return column;
  });
}

function getByCriteria(descriptor, criteria, def) {
  try {
    const result = without(
      descriptor.columns.map(
        (elem, index) =>
          !isNil(elem[criteria]) && elem[criteria] !== false ? index : undefined
      ),
      undefined
    );
    return result.length ? result : [def];
  } catch (e) {
    return [def];
  }
}

function getByValue(descriptor, criteria, value, def) {
  try {
    const result = without(
      descriptor.columns.map(
        (elem, index) => (elem[criteria] == value ? index : undefined)
      ),
      undefined
    );
    return result.length ? result : [def];
  } catch (e) {
    return [def];
  }
}

export default parseDescriptor;
export { getByCriteria, getByValue };
