import React, { Component } from "react";
import Transition from "react-transition-group/Transition";
import "./scrollinandout.css";

export default class ScrollInAndOut extends Component {
  constructor() {
    super();
    this.defaultStyle = {
      overflow: "hidden",
      maxHeight: 0
    };
    this.state = {
      maxHeight: 1000,
      duration: 500
    };
    ::this.updateTransitions();
  }

  updateTransitions() {
    const { maxHeight, duration } = this.state;
    this.defaultStyle.transition = `max-height ${duration}ms linear`;
    this.transitionStyles = {
      entering: { maxHeight: `${maxHeight}px` },
      entered: {
        maxHeight: `1000px`,
        transition: "none"
      },
      exiting: {
        maxHeight: `${maxHeight}px`,
        transition: "none"
      },
      exited: { maxHeight: "0" }
    };
  }

  updateDuration(maxHeight) {
    return maxHeight * 3.5;
  }

  render() {
    const { children, className } = this.props;
    const { duration } = this.state;
    return (
      <Transition
        {...this.props}
        onEntered={element => {
          const height = element.offsetHeight;
          this.setState(
            {
              maxHeight: height,
              duration: this.updateDuration(height)
            },
            this.updateTransitions
          );
        }}
        timeout={{ enter: duration, exit: 1 }}
        appear
      >
        {state => {
          return (
            <div
              className={className}
              style={{ ...this.defaultStyle, ...this.transitionStyles[state] }}
            >
              {children}
            </div>
          );
        }}
      </Transition>
    );
  }
}
