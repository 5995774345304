import React from "react";
import PropTypes from "prop-types";
import { debounceSyntheticEvent as debounce } from "../util/debounce";
import { connect } from "react-redux";
import { createSearchAction, getSearchSelectors } from "redux-search";

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.changeHandler = debounce(this.changeHandler, 700, this);
  }
  changeHandler(event) {
    const { value } = event.target;
    this.props.searchRows(value);
    this.props.goToPage(1);
  }

  componentDidMount = () => {
    this.props.searchRows("");
  };

  render() {
    return (
      <div className="filter">
        {this.props.children}
        <div
          className="search"
          onClick={event => {
            if (event.target.tagName !== "INPUT") {
              const input = event.target.children.item(0);
              input.focus();
            }
          }}
        >
          <input onChange={this.changeHandler} />
        </div>
      </div>
    );
  }
}

const actions = {
  searchRows: createSearchAction("rows"),
  goToPage: page => ({ type: "CHANGE_PAGE", page })
};

export default connect(undefined, actions)(Filter);
