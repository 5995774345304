import React from "react";
import PropTypes from "prop-types";
import FilePopup from "../util/FilePopup";

class LinkPopup extends React.Component {
  componentDidMount() {
    this.popup = new FilePopup(this.refs.link);
  }

  render() {
    return (
      <a ref="link" href={this.props.href}>
        {this.props.name}
      </a>
    );
  }
}

export default LinkPopup;
