import { without } from "lodash";
export default function getByCriteria(descriptor, criteria, def) {
  try {
    return without(
      descriptor.columns.map((elem, index) => {
        return elem[criteria] ? index : undefined;
      }),
      undefined
    );
  } catch (e) {
    return [def];
  }
}
