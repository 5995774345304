import "../../css/file-popup.css";
import localize from "../util/localize";
import filesize from "filesize";
import { memoize } from "lodash";
const qs = document.querySelector.bind(document);
const ce = document.createElement.bind(document);

function createHTML() {
  if (createHTML.html) return createHTML.html;
  const html = ce("div");
  html.id = "file-popup";
  const close = ce("i");
  close.classList.add("fa");
  close.classList.add("fa-close");
  close.classList.add("close");
  html.appendChild(close);
  close.addEventListener("click", e => (html.style.display = "none"));
  const download = ce("a");
  download.classList.add("download");
  download.target = "blank_";
  const icon = ce("i");
  icon.classList.add("fa");
  icon.classList.add("fa-download");
  download.appendChild(icon);
  html.appendChild(download);
  const filename = ce("div");
  filename.classList.add("filename");
  html.appendChild(filename);
  const filesize = ce("div");
  filesize.classList.add("filesize");
  html.appendChild(filesize);
  createHTML.html = html;
  document.body.appendChild(html);
  return createHTML.html;
}

class FilePopup {
  constructor(element) {
    this.element = element;
    this.href = element.getAttribute("href");
    this.html = createHTML();
    this.element.addEventListener("mousedown", e => e.preventDefault());
    this.element.addEventListener("contextmenu", e => e.preventDefault());
    this.element.addEventListener("click", e => {
      e.preventDefault();
      ::this.open(e);
    });
    // ::this.getData(this.href);
  }

  getData(href) {
    if (this.data !== undefined) return Promise.resolve(this.data);
    return fetch(href).then(response => response.json()).then(json => {
      this.data = json.items[0] || false;
      if (!this.data) {
        this.element.classList.add("disabled");
        return false;
      }
      return this.data;
    });
  }

  open(event) {
    this.getData(this.href).then(data => {
      if (data) {
        const offset = $(event.target).offset();
        this.html.style.top = `${offset.top + event.target.offsetHeight + 5}px`;
        this.html.style.left = `${offset.left}px`;
        this.html.getElementsByClassName("filename")[0].innerHTML =
          data.filename;
        this.html.getElementsByClassName("filesize")[0].innerHTML = filesize(
          data.filesize
        );
        this.html.getElementsByClassName("download")[0].href = localize(
          data.uri,
          "es"
        );
        this.html.style.display = "inherit";
      }
    });
  }
}

export default FilePopup;
