import React  from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Row from "./Row";
import GroupRow from "./GroupRow";
import { connect } from "react-redux";
import { isEmpty, sortBy, clone, forOwn, reduce, compact, find } from "lodash";

class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: undefined
    };
  }

  static filterRow(filter, row) {
    let valid = true;
    forOwn(filter, (value, key) => {
      if (typeof value !== "undefined" && row[key].value !== value) {
        valid = false;
      }
    });
    return valid;
  }

  displaySorted(keys, criteria) {
    const { rows, contents, documents, headers } = this.props;
    let sortedKeys = clone(keys);
    // Ordenar claves mostradas
    for (const criteriaKey in criteria) {
      switch (criteria[criteriaKey]) {
        case "asc":
          sortedKeys = sortBy(sortedKeys, key => rows[key].row[criteriaKey].value);
          break;
        case "desc":
          sortedKeys = sortBy(
            sortedKeys,
            key => rows[key].row[criteriaKey].value
          ).reverse();
          break;
      }
    }
    const { currentPage, pageSize, filter,selected } = this.props;
    const amount = sortedKeys.length;
    const start = pageSize * (currentPage - 1);
    const end = start + pageSize;
    const display = sortedKeys.slice(start, end);
    return display
      .filter(key => this.constructor.filterRow(filter, rows[key]))
      .map((key, index) => {
        const row = rows[key];
        const rowId = row.id;
        const content = contents[rowId];
        const document = documents[rowId];
        return (
          <Row
            key={rowId}
            row={row.row}
            id={rowId}
            content={content}
            document={document}
            headers={headers}
            isSelected={rowId === selected}
            opened={this.state.opened === rowId}
            updateOpened={opened => this.setState({ opened })}
          />
        );
      });
  }

  displayGrouped(keys, criteria) {
    const { rows, contents, documents, headers } = this.props;
    let sortedKeys = clone(keys);
    // Ordenar claves mostradas
    for (const criteriaKey in criteria) {
      if (criteriaKey === "count") {
        switch (criteria[criteriaKey]) {
          case "asc":
            sortedKeys = sortBy(
              sortedKeys,
              key => Object.keys(rows[key].rows).length
            );
            break;
          case "desc":
            sortedKeys = sortBy(
              sortedKeys,
              key => Object.keys(rows[key].rows).length
            ).reverse();
            break;
        }
      } else {
        switch (criteria[criteriaKey]) {
          case "asc":
            sortedKeys = sortBy(
              sortedKeys,
              key => rows[key].row[criteriaKey].value
            );
            break;
          case "desc":
            sortedKeys = sortBy(
              sortedKeys,
              key => rows[key].row[criteriaKey].value
            ).reverse();
            break;
        }
      }
    }
    const { currentPage, pageSize, filter,selected } = this.props;
    const amount = sortedKeys.length;
    const start = pageSize * (currentPage - 1);
    const end = start + pageSize;
    const display = sortedKeys.slice(start, end);
    return display
      .filter(key => this.constructor.filterRow(filter, rows[key]))
      .map((key, index) => {
      return <GroupRow
        key={key}
        id={key}
        isSelected={key === selected}
        opened={this.state.opened === key}
        updateOpened={opened => this.setState({ opened })}
        />}
      );
  }

  render() {
    const { searchResult, rows } = this.props;
    const keys = searchResult.text.length
      ? searchResult.result
      : Object.keys(rows);
    return (
      <div
        className="overflow-container"
        ref={element => {
          if (element !== null) {
            element.classList.remove("overflowed");
            const outer = element.getBoundingClientRect().width;
            const inner = element.children.item(0).getBoundingClientRect()
              .width;
            if (outer < inner) {
              element.classList.add("overflowed");
            }
          }
        }}
      >
        <div className="inner-table">
          <Header />
          {this.props.grouped
            ? this.displayGrouped(keys, this.props.criteria)
            : keys.length
              ? this.displaySorted(keys, this.props.criteria)
              : <div className="no-result" />}
        </div>
      </div>
    );
  }

  static contextTypes = {
    event: PropTypes.object
  }
}

export default connect(state => {
  return {
    searchResult: state.search.rows,
    rows: state.data.rows,
    contents: state.data.contents,
    documents: state.data.documents,
    headers: state.data.columns
      ? state.data.columns.map(item => item.title)
      : state.data.headings,
    criteria: state.criteria,
    currentPage: state.paginator.current,
    pageSize: state.paginator.pageSize,
    filter: state.filter,
    grouped: reduce(state.groups, (test, value) => test || value),
  };
})(Body);
