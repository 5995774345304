import React  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduce, without } from "lodash";
import localize from "../util/localize";
import ColumnFilter from "../components/ColumnFilter";
import ColumnGroup from "../components/ColumnGroup";

class SubHeader extends React.Component {
  nextPosition(position) {
    const positions = ["neutral", "asc", "desc"];
    const number =
      positions.indexOf(position) + 1 >= 3
        ? 0
        : positions.indexOf(position) + 1;
    return positions[number];
  }
  render() {
    const { displays, columns, headers } = this.props;
    return (
      <div className="subHeader">
        <div className="cell icon" />
        {displays.map((display, index) => {
          const header = headers[display];
          return (
            <div
              key={index}
              data-metatype={columns[display].type}
              className={"cell " + columns[display].style}
            >
              {localize(header, this.context.locale)}
            </div>
          );
        })}
      </div>
    );
  }

  static contextTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default connect(state => ({
  headers: state.data.columns
    ? state.data.columns.map(item => item.title)
    : state.data.headings,
  columns: state.data.columns
}))(SubHeader);
