import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import localize from "../util/localize";

const PageSizeSelector = props => {
  const label = localize(
    {
      es: "Mostrar",
      ca: "Veure",
      en: "Show"
    },
    "es"
  );
  if (props.amount <= 10) {
    return null;
  }
  return (
    <select
      defaultValue="10"
      onChange={event => {
        props.changeSize(parseInt(event.target.value));
        props.goToPage(1);
      }}
    >
      <option value="10">
        {label} 10
      </option>
      {props.amount > 10
        ? <option value="20">
            {label} 20
          </option>
        : null}
      {props.amount > 20
        ? <option value="30">
            {label} 30
          </option>
        : null}
    </select>
  );
};

export default connect(
  state => ({
    amount:
      state.search.rows.result.length || Object.keys(state.data.rows).length
  }),
  dispatch => ({
    changeSize: size => dispatch({ type: "PAGE_SIZE", size }),
    goToPage: page => dispatch({ type: "CHANGE_PAGE", page })
  })
)(PageSizeSelector);
