import React from "react";
import PropTypes from "prop-types";
import { flatten } from "lodash";
import { connect } from "react-redux";
import formatter from "../util/format";
import localize from "../util/localize";
import LinkPopup from "../components/LinkPopup";

class Row extends React.Component {
  constructor(props) {
    super(props);
  }

  select(id) {
    if (id === this.props.id) {
      this.setState(
        { selected: true },
        setTimeout(() => this.setState({ selected: false }), 3000)
      );
    }
  }

  render() {
    const { columns, id, isSelected } = this.props;
    const { event } = this.context;
    const row = this.context.file ? this.props.row.slice(1) : this.props.row;
    const fileExtension = new RegExp("\\.(\\w{2,5})$");

    return (
      <div
        className={`row${isSelected ? " selected" : ""}`}
        onClick={e => {
          event && event.onClick && event.onClick({ row, id });
        }}
      >
        {row.map(
          item =>
            /^\<a/.test(item.value) ? (
              <div
                key={item.index}
                data-metatype={columns && columns[item.index].type}
                data-label={localize(
                  this.props.headers[item.index],
                  this.context.locale
                )}
                className={`cell${
                  columns ? " " + columns[item.index].style : ""
                }`}
                dangerouslySetInnerHTML={{ __html: item.value }}
              />
            ) : (
              <div
                key={item.index}
                data-metatype={columns && columns[item.index].type}
                data-label={localize(
                  this.props.headers[item.index],
                  this.context.locale
                )}
                className={`cell${
                  columns ? " " + columns[item.index].style : ""
                }`}
              >
                {columns
                  ? formatter(item.value, columns[item.index])
                  : item.value}
              </div>
            )
        )}
        {this.context.file ? (
          <div className="cell icon download">
            <span>
              {this.props.row[0].value &&
                (this.context.isDirectLink ? (
                  <a
                    target="_blank"
                    href={`${this.context.filePath}/${
                      fileExtension.test(this.props.row[0].value)
                        ? this.props.row[0].value
                        : this.props.row[0].value + ".pdf"
                    }`}
                  />
                ) : (
                  <LinkPopup
                    href={`${this.context.filePath}${
                      fileExtension.test(this.props.row[0].value)
                        ? this.props.row[0].value
                        : this.props.row[0].value + ".pdf"
                    }`}
                  />
                ))}
            </span>
          </div>
        ) : null}
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array.isRequired
  };

  static contextTypes = {
    filePath: PropTypes.string.isRequired,
    file: PropTypes.bool.isRequired,
    isDirectLink: PropTypes.bool.isRequired,
    locale: PropTypes.string,
    event: PropTypes.object
  };
}

export default connect(state => ({ columns: state.data.columns }))(Row);
