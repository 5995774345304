import React, { PropTypes } from "react";
import RowDraw from "../util/RowDraw";

class TableBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: props.display
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.display !== nextProps.display) {
      this.setState({
        display: nextProps.display
      });
    }
  }

  handle(code) {
    const plus = "fa-plus-square-o";
    const minus = "fa-minus-square-o";
    return event => {
      //Selecciona todos los elementos necesarios y en funcion de si existe o no la clase plus cambias las clases pertinentes y propaga el estado
      const target = event.target;
      const rowClass = target.closest("tr").classList;
      const eventClass = target.classList;
      const status = eventClass.contains(plus);
      eventClass.toggle(plus, !status);
      eventClass.toggle(minus, status);
      eventClass.toggle("expanded", status);
      this.props.change(code, status);
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.display !== nextProps.display ||
      this.props.item !== nextProps.item
    );
  }

  render() {
    let item = this.props.item;
    if (this.state.display) {
      return (
        <tr
          key={`${item.code}-${this.props.level}`}
          data-level={this.props.level}
        >
          <td
            key={`td--0`}
            className="icon"
            style={{ paddingLeft: `${15 * (this.props.level + 1)}px` }}
          >
            <span>
              <i
                onClick={::this.handle(item.code)}
                className={`fa fa-plus-square-o collapse-icon ${
                  this.props.level ? "black" : "blue"
                } ${
                  this.props.level ? (this.props.level == 1 ? "" : "") : "fa-lg"
                }`}
              />
            </span>
          </td>
          {RowDraw(
            item.row,
            item.rowIndex,
            this.context.descriptor,
            this.props.totals
          )}
        </tr>
      );
    } else {
      return null;
    }
  }

  static contextTypes = {
    descriptor: PropTypes.object.isRequired
  };
}

export default TableBranch;
