import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

class Paginator extends React.Component {
  validatePage(pageNumber, max) {
    const number = parseInt(pageNumber);
    if (number > 0 && number <= max && number !== this.props.current) {
      this.props.goToPage(number);
    }
  }

  render() {
    const max = Math.ceil(this.props.amount / this.props.pageSize);
    if (max === 1) { return null; }
    return (
      <div className="paginator">
        <span className="prev" onClick={() => this.validatePage(this.props.current - 1, max)}><i className="fa fa-angle-left" /></span>
        <span className="pages">
          <span className="current"><input min="1" max={max} step="1" size="2" value={this.props.current} onFocus={event => event.target.setSelectionRange(0, event.target.value.length)} onChange={event => this.validatePage(event.target.value, max)} /></span>
          <span className="max">{max}</span>
        </span>
        <span className="next" onClick={() => this.validatePage(this.props.current + 1, max)} ><i className="fa fa-angle-right" /></span>
      </div>
    );
  }
}

export default connect(state => ({
  amount: state.search.rows.result.length || Object.keys(state.data.rows).length,
  current: state.paginator.current,
  pageSize: state.paginator.pageSize,
}), dispatch => ({
  goToPage: page => dispatch({ type: 'CHANGE_PAGE', page }),
}))(Paginator);
