import { combineReducers, compose, createStore } from "redux";
import { reducer as searchReducer, reduxSearch } from "redux-search";
import agroup from "../util/agroup";
import {
  cloneDeep as clone,
  find,
  findIndex,
  findLastIndex,
  without,
  uniqBy,
  reduce,
  sortBy
} from "lodash";

function initialize(initial) {
  function reducer(state = {}, action) {
    switch (action.type) {
      case "GROUPS_UPDATE":
        const group = agroup(state, action.group);
        return group;
      default:
        return state;
    }
  }

  function filter(state = {}, action) {
    switch (action.type) {
      case "FILTER_UPDATE":
        const newState = clone(state);
        newState[action.id] =
          action.filter === "none" ? undefined : action.filter;
        return newState;
      default:
        return state;
    }
  }

  function groups(state = {}, action) {
    switch (action.type) {
      case "GROUPS_UPDATE":
        return action.group;
      default:
        return state;
    }
  }

  function criteria(state = {}, action) {
    switch (action.type) {
      case "UPDATE_CRITERIA":
        const newState = clone(state);
        newState[action.index] = action.direction;
        return newState;
      default:
        return state;
    }
  }

  function paginator(
    state = {
      current: 1,
      pageSize: 10
    },
    action
  ) {
    const newState = clone(state);
    switch (action.type) {
      case "CHANGE_PAGE":
        newState.current = action.page;
        return newState;
      case "PAGE_SIZE":
        newState.pageSize = action.size;
        return newState;
      default:
        return state;
    }
  }
  const rootReducer = combineReducers({
    search: searchReducer,
    data: reducer,
    criteria,
    paginator,
    filter,
    groups
  });

  const enhancer = compose(
    reduxSearch({
      resourceIndexes: {
        rows: ({ resources, indexDocument, state }) => {
          const isGroupable = reduce(
            state.groups,
            (test, value) => test || value
          );
          if (isGroupable) {
            resources.forEach(resource => {
              resource.rows.forEach(row => {
                row.row.forEach(a =>
                  indexDocument(resource.groupId, a.value || "")
                );
              });
            });
          } else {
            resources.forEach((resource, index) => {
              resource.row.forEach(cell =>
                indexDocument(index, cell.value.toString())
              );
            });
          }
        }
      },
      resourceSelector: (resourceName, state) => state.data[resourceName]
    })
  );
  const initialState = agroup(initial);
  delete initialState.groups;
  const store = createStore(
    rootReducer,
    {
      data: initialState,
      groups: initial.groups
    },
    enhancer
  );
  return store;
}

export default initialize;
