import React  from "react";
import PropTypes from "prop-types";
import { flattenDeep, isEmpty, compact, reduce, find } from "lodash";
import { connect } from "react-redux";
import formatter from "../util/format";
import localize from "../util/localize";
import LinkPopup from "../components/LinkPopup";
import ScrollInAndOut from "../transitions/ScrollInAndOut";

class SubRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      angle: "right"
    };
  }

  render() {
    const { columns, row, displays, headers, opened, id } = this.props;
    const {event} = this.context;
    const hasContent =
      !isEmpty(this.props.content) || !isEmpty(this.props.document);
    const isBig =
      reduce(this.props.content, (sum, elem) => sum + elem.value, "").length >
      250;
    const fileExtension = new RegExp("\\.(\\w{2,5})$");
    return (
      <div
        className={`row ${opened ? "open" : ""}`}
        onClick={() => {
          event && event.onSelect && event.onSelect({row,id})
          if (hasContent) this.props.updateOpened();
        }}
      >
        <div className="cells">
          <div
            className="cell icon"
            onClick={e => {
              if (hasContent) {
                opened
                  ? this.props.updateOpened(true)
                  : this.props.updateOpened();
              }
              e.stopPropagation();
            }}
          >
            {hasContent ? (
              <i className={`fa fa-angle-${opened ? "down" : "right"}`} />
            ) : null}
          </div>
          {displays.map((display, index) => {
            const cell = find(row, ["index", display]);
            return (
              <div
                key={index}
                data-label={localize(headers[display], this.context.locale)}
                data-metatype={columns[display].type}
                className={"cell " + columns[display].style}
              >
                {columns ? formatter(cell.value, columns[display]) : cell.value}
              </div>
            );
          })}
        </div>
        {hasContent ? (
          <ScrollInAndOut
            in={opened}
            className={`contents ${isBig ? "big" : ""}`}
          >
            {!isEmpty(this.props.content) ? (
              <div className="box container">
                {this.props.content.map(item => (
                  <div
                    key={item.index}
                    className={`content${item.value ? "" : " inactive"}`}
                  >
                    <span className="label">
                      {localize(
                        this.props.headers[item.index],
                        this.context.locale
                      )}
                    </span>
                    {columns ? (
                      formatter(item.value, columns[item.index])
                    ) : (
                      item.value
                    )}
                  </div>
                ))}
              </div>
            ) : null}
            {!isEmpty(this.props.document) &&
            reduce(
              this.props.document,
              (test, doc) => {
                if (!isEmpty(doc.value)) {
                  return true;
                } else {
                  return test;
                }
              },
              false
            ) ? (
              <div className="documents container">
                <div className="title">
                  {localize(
                    {
                      ca: "Documents",
                      es: "Documentos"
                    },
                    this.context.locale
                  )}
                </div>
                <div className="box">
                  {compact(
                    flattenDeep(
                      this.props.document.map(item =>
                        item.value.split(",").map(
                          (doc, ind) =>
                            !doc ? (
                              undefined
                            ) : (
                              <div
                                key={`${item.index}-${ind}`}
                                className="document content"
                              >
                                {this.context.isDirectLink ? (
                                  <a
                                    onMouseDown={event => {
                                      event.preventDefault();
                                    }}
                                    href={`${this.context
                                      .filePath}/${fileExtension.test(doc)
                                      ? doc
                                      : doc + ".pdf"}`}
                                    target="_blank"
                                  >
                                    <span className="label">
                                      {localize(
                                        this.props.headers[item.index],
                                        this.context.locale
                                      )}
                                    </span>
                                    {doc}
                                  </a>
                                ) : (
                                  <LinkPopup
                                    name={doc}
                                    href={`${this.context
                                      .filePath}${fileExtension.test(doc)
                                      ? doc
                                      : doc + ".pdf"}`}
                                  />
                                )}
                              </div>
                            )
                        )
                      )
                    )
                  )}
                </div>
              </div>
            ) : null}
          </ScrollInAndOut>
        ) : null}
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array.isRequired,
    content: PropTypes.array.isRequired,
    document: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
  };

  static contextTypes = {
    event: PropTypes.object,
    filePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
  };
}

export default connect(state => ({
  columns: state.data.columns,
  groups: state.groups,
  grouped: reduce(state.groups, (test, value) => test || value)
}))(SubRow);
