import React from "react";
import PropTypes from "prop-types";
import SubRow from "./SubRow";
import SubHeader from "./SubHeader";
import { connect } from "react-redux";

class SubTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  updateOpened(id) {
    return (close = false) => {
      this.setState({
        opened: close ? false : id
      });
    };
  }
  render() {
    const {
      rows,
      contents,
      documents,
      headers,
      columns,
      displays
    } = this.props;
    return (
      <div className="sub-table">
        <SubHeader displays={displays} />
        {rows.map((row, index) => {
          const content = contents[row.id];
          const document = documents[row.id];
          return (
            <SubRow
              key={row.id}
              row={row.row}
              id={row.id}
              content={content}
              opened={this.state.opened === row.id}
              updateOpened={::this.updateOpened(row.id)}
              document={document}
              headers={headers}
              displays={displays}
            />
          );
        })}
      </div>
    );
  }
}

export default connect(state => ({
  contents: state.data.contents,
  documents: state.data.documents,
  columns: state.data.columns,
  headers: state.data.columns
    ? state.data.columns.map(item => item.title)
    : state.data.headings
}))(SubTable);
