import React, { PropTypes } from "react";
import Header from "./Header";
import Row from "./Row";
import { connect } from "react-redux";
import { isEmpty, sortBy, clone, forOwn } from "lodash";

class Body extends React.Component {
  static filterRow(filter, row) {
    let valid = true;
    forOwn(filter, (value, key) => {
      if (typeof value !== "undefined" && row[key].value !== value) {
        valid = false;
      }
    });
    return valid;
  }

  displaySorted(keys, criteria) {
    const { rows, defaultNames, pdfs, headers } = this.props;
    let sortedKeys = clone(keys);
    // Ordenar claves mostradas
    for (const criteriaKey in criteria) {
      switch (criteria[criteriaKey]) {
        case "asc":
          sortedKeys = sortBy(sortedKeys, key => rows[key][criteriaKey].value);
          break;
        case "desc":
          sortedKeys = sortBy(
            sortedKeys,
            key => rows[key][criteriaKey].value
          ).reverse();
          break;
      }
    }
    const { currentPage, pageSize, filter, selected } = this.props;
    const amount = sortedKeys.length;
    const start = pageSize * (currentPage - 1);
    const end = start + pageSize;
    const display = sortedKeys.slice(start, end);
    return display
      .filter(key => this.constructor.filterRow(filter, rows[key]))
      .map((key, index) => {
        const row = rows[key];
        const rowId = row.id;
        const defaultName = defaultNames[rowId];
        const pdf = pdfs[rowId];
        return (
          <Row
            key={rowId}
            row={row.row}
            id={row.id}
            isSelected={selected === row.id}
            defaultName={defaultName}
            pdf={pdf}
            headers={headers}
          />
        );
      });
  }

  render() {
    const { searchResult, rows } = this.props;
    const keys = searchResult.text.length
      ? searchResult.result
      : Object.keys(rows);
    return (
      <div
        className="overflow-container"
        ref={element => {
          if (element !== null) {
            element.classList.remove("overflowed");
            const outer = element.getBoundingClientRect().width;
            const inner = element.children.item(0).getBoundingClientRect()
              .width;
            if (outer < inner) {
              element.classList.add("overflowed");
            }
          }
        }}
      >
        <div className="inner-table">
          <Header />{" "}
          {keys.length ? (
            this.displaySorted(keys, this.props.criteria)
          ) : (
            <div className="no-result" />
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  searchResult: state.search.rows,
  rows: state.data.rows,
  defaultNames: state.data.defaultNames,
  pdfs: state.data.pdfs,
  headers: state.data.columns
    ? state.data.columns.map(item => item.title)
    : state.data.headings,
  criteria: state.criteria,
  currentPage: state.paginator.current,
  pageSize: state.paginator.pageSize,
  filter: state.filter
}))(Body);
