import React from "react";
import PropTypes from "prop-types";
import ContentTable from "./ContentTable";
import FilesTable from "./FilesTable";
import GroupedTable from "./GroupedTable";
import TreeTable from "./TreeTable";
import parseDescriptor from "./util/parseDescriptor";
/* import getSQL from "./util/getSQL"; */
import Table from "./Table";
import { cloneDeep } from "lodash";
// import '../css/contentTable.css';

function tableTypes(type) {
  switch (type) {
    case "content":
      return ContentTable;
    case "files":
      return FilesTable;
    case "grouped":
      return GroupedTable;
    case "collapsible":
      return TreeTable; // Collapsible table
    default:
      return Table; // Flat table
  }
}

function mediaQuery() {
  if (window.matchMedia("(max-width: 450px)").matches) {
    return 3;
  } else if (
    window.matchMedia("(min-width: 451px) and (max-width: 768px)").matches
  ) {
    return 2;
  } else if (
    window.matchMedia("(min-width: 769px) and (max-width: 1024px)").matches
  ) {
    return 1;
  } else if (window.matchMedia("(min-width: 1025px)").matches) {
    return 0;
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table: cloneDeep(props.table)
    };
  }

  componentDidMount() {
    /*     if (this.props.table.query) {
      this.getSQLTable(this.props.table);
    } */
    this.props.selectRow(::this.select);
  }

  componentWillMount() {
    const media = mediaQuery();
    this.setState({ media });
    window.addEventListener("resize", ::this.mediaChange);
  }

  /*   getSQLTable(table) {
    getSQL(this.props.sqlBasepath, table.query).then(response => {
      response = response[0];
      const header = response.meta.map(it => it.column);
      response.data.unshift(header);
      table.data = response.data;
      this.setState({ table });
    });
  } */

  mediaChange() {
    const media = mediaQuery();
    this.setState({ media });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.table !== nextState.table ||
      this.state.selected !== nextState.selected
    );
  }

  select(id) {
    this.setState({ selected: id });
  }

  getChildContext() {
    const { isDirectLink, realm, event } = this.props;
    return { isDirectLink, realm, event };
  }

  render() {
    const table = parseDescriptor(this.state.table, this.props.locale);
    const TypedTable = tableTypes(
      (table.descriptor && table.descriptor.type) || table.type
    );
    const filePath = this.props.isDirectLink
      ? this.props.filePath
      : `https://api.digitalvalue.es/${
          this.props.realm
        }/collections/files?filename=`;
    return (
      <div className="table-wrapper">
        <TypedTable
          table={table}
          locale={this.props.locale}
          filePath={filePath}
          media={this.state.media}
          selected={this.state.selected}
        />
      </div>
    );
  }

  static defaultProps = {
    locale: "es",
    isDirectLink: false,
    realm: "alcantir"
  };

  static childContextTypes = {
    isDirectLink: PropTypes.bool,
    realm: PropTypes.string,
    event: PropTypes.object
  };
}

export default App;
