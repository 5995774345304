import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import Table from "./Table";
import initialize from "./store";
import { uniqueId, clone, compact } from "lodash";
import { formatNumber } from "../util/format";

function normalizeCell(cell, column) {
  const numbers = ["currency", "number", "percentage", "progress"];
  if (!column || numbers.indexOf(column.type) < 0) {
    return cell;
  }
  return formatNumber(cell);
}

function transform(table) {
  const columns = table.descriptor && table.descriptor.columns;
  const origin = clone(table.data);
  let headings;
  const result = {
    rows: []
  };
  origin.forEach((row, id) => {
    if (id === 0) headings = row;
    else
      result.rows.push({
        id: id.toString(),
        row: compact(
          row.map((cell, index) => {
            if (!columns || !columns[index].isHidden) {
              return {
                index,
                value: normalizeCell(cell, columns && columns[index])
              };
            }
          })
        )
      });
  });
  return {
    ...result,
    columns,
    headings,
    origin
  };
}

export default class App extends React.Component {
  componentWillMount = () => {
    const store = initialize(transform(this.props.table));
    this.setState({ store });
  };

  componentWillUnmount = () => {};

  render() {
    const { table, locale, filePath, selected } = this.props;
    const { store } = this.state;
    return (
      <Provider key={uniqueId()} store={store}>
        <Table
          download={table}
          locale={locale}
          filePath={filePath}
          file={table.type === "lastfile"}
          selected={selected}
        />
      </Provider>
    );
  }

  static propTypes = {
    filePath: PropTypes.string,
    table: PropTypes.object
  };
}
