import React from "react";
import PropTypes from "prop-types";
import localize from "../util/localize";

function parseTable(table, locale) {
  const data = table.data;
  const descriptor = table.descriptor;
  const rows = [[]];
  const localizedColumns = [];
  const header = rows[0];
  descriptor.columns.forEach((column, index) => {
    if (column.locale === locale || column.locale === undefined) {
      localizedColumns.push(index);
      header.push(localize(column.title, locale));
    }
  });
  data.forEach(row => {
    rows.push(
      row.filter((item, index) => localizedColumns.indexOf(index) > -1)
    );
  });
  return rows;
}

const Download = (props, context) => {
  function exportToCsv(filename, table, locale) {
    //const rows = parseTable(table, locale); Elminado para devolver siempre la tabla original en el csv
    const rows = table.data;
    const processRow = function(row) {
      let finalVal = "";
      for (let j = 0; j < row.length; j++) {
        let innerValue = row[j] === null ? "" : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = `"${result}"`;
        }
        if (j > 0) {
          finalVal += ",";
        }
        finalVal += result;
      }
      return `${finalVal}\n`;
    };

    let csvFile = "";
    for (let i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <a
      className="download-button"
      onClick={() => {
        this::exportToCsv(
          localize(props.table.title, context.locale || "ca", "informe") +
            ".csv",
          props.table,
          context.locale
        );
      }}
    >
      <i className="fa fa-download" />
      CSV
    </a>
  );
};

Download.contextTypes = {
  locale: PropTypes.string
};

export default Download;
