import React, { PropTypes } from "react";
import Filter from "../components/Filter";
import Body from "./Body";
import Paginator from "../components/Paginator";
import Download from "../components/Download";
import PageSizeSelector from "../components/PageSizeSelector";

class Table extends React.Component {
  getChildContext() {
    const { filePath, locale } = this.props;
    return { filePath, locale };
  }
  render() {
    return (
      <div className="content-table">
        <Filter>
          <PageSizeSelector />
        </Filter>
        <Body selected={this.props.selected} />
        <Paginator />
        <Download table={this.props.download} />
      </div>
    );
  }

  static childContextTypes = {
    filePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
  };

  static propTypes = {
    filePath: PropTypes.string.isRequired
  };

  static defaultProps = {
    filePath: "data",
    locale: "es"
  };
}

export default Table;
