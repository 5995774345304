import {
  clone,
  without,
  sortBy,
  uniqBy,
  find,
  findIndex,
  findLastIndex,
  reduce,
  memoize
} from "lodash";
import { formatNumber } from "./format";

const operations = {
  sum: (accumulator, value) => (accumulator || 0) + formatNumber(value),
  max: (accumulator, value) =>
    accumulator >= formatNumber(value) ? accumulator : formatNumber(value),
  min: (accumulator, value) =>
    accumulator <= formatNumber(value) ? accumulator : formatNumber(value),
  first: (accumulator, value) => accumulator || formatNumber(value),
  count: accumulator => (accumulator || 0) + 1
};

const getJoinedGroups = memoize(
  (groups, element) => groups.map(key => element[key]).join(),
  (groups, element) => JSON.stringify({ groups, element })
);

const joinAgrupation = memoize(function(groups, data, original, columns) {
  let keysIndex = [];
  data.forEach((elem, position) => {
    keysIndex.push({
      text: getJoinedGroups(groups, elem),
      position
    });
  });
  keysIndex = sortBy(keysIndex, ["text"]);
  const group = uniqBy(data, row => getJoinedGroups(groups, row)).map(
    (e, id) => {
      const element = getJoinedGroups(groups, e);
      const start = findIndex(keysIndex, ["text", element]);
      const end = findLastIndex(keysIndex, ["text", element]);
      const keys = keysIndex.slice(start, end + 1).map(item => {
        return item.position;
      });
      const row = without(
        original[id].row.map(elem => {
          if (columns[elem.index].isGroupable) {
            return { index: elem.index, value: e[elem.index] };
          } else if (
            columns[elem.index].type === "currency" ||
            columns[elem.index].agregateFunc
          ) {
            return {
              index: elem.index,
              value: reduce(
                keys,
                (accumulator, key) => {
                  const value = data[key][elem.index];
                  return operations[columns[elem.index].agregateFunc || "sum"](
                    accumulator,
                    value
                  );
                },
                undefined
              )
            };
          }
          return undefined;
        }),
        undefined
      );
      const rows = [];
      keys.forEach(key => {
        rows.push(original[key]);
      });
      return {
        rows,
        row,
        groupId: id
      };
    }
  );
  return group;
});

export default function agroupator(state, actionGroup = state.groups) {
  const newState = clone(state);
  const groups = without(
    Object.keys(actionGroup).map(
      id => (actionGroup[id] ? parseInt(id) : undefined)
    ),
    undefined
  );
  if (groups.length > 0) {
    let result = joinAgrupation(
      groups,
      state.data,
      state.original,
      state.columns
    );
    newState.rows = result;
    return newState;
  }
  newState.rows = newState.original;
  return newState;
}
