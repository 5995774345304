import React  from "react";
import PropTypes from "prop-types";
import { clone, cloneDeep, reduce, compact, forOwn, transform } from "lodash";
import TableBranch from "./TableBranch";
import TableLeaf from "./TableLeaf";
import RowDraw from "../util/RowDraw";
import { formatNumber } from "../util/RowDraw";
import { getByCriteria } from "../util/parseDescriptor";

class TableTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayes: {}
    };
  }

  getChildContext() {
    let desc = !this.props.descriptor
      ? {
          columns: [
            {
              title: "cod",
              isHidden: true
            }
          ]
        }
      : this.props.descriptor;
    return { descriptor: desc };
  }

  format(text) {
    return isNaN(formatNumber(text)) ? text : formatNumber(text);
  }

  render() {
    const positions = getByCriteria(
      this.props.descriptor,
      "isGraphable",
      false
    );
    let data = clone(this.props.table.data);
    data.shift();
    let body = this.collapsible(data, this.props.descriptor.collapse),
      totals = {},
      sort;
    if (positions[0] !== false) {
      positions.forEach(
        position =>
          (totals[position] = reduce(
            body,
            (sum, n, key) => sum + parseFloat(n.row[position]),
            0
          ))
      );
    }
    if (this.props.sort !== undefined) {
      this.props.sort.forEach((elem, index) => {
        if (typeof elem !== "undefined") sort = index;
      });
      if (!!sort) {
        if (this.props.sort[sort] == "asc") {
          body.sort((a, b) => {
            if (this.format(a.row[sort]) < this.format(b.row[sort])) return -1;
            if (this.format(a.row[sort]) > this.format(b.row[sort])) return 1;
            return 0;
          });
        } else if (this.props.sort[sort] == "desc") {
          body.sort((a, b) => {
            if (this.format(a.row[sort]) > this.format(b.row[sort])) return -1;
            if (this.format(a.row[sort]) < this.format(b.row[sort])) return 1;
            return 0;
          });
        }
      }
    }
    return (
      <tbody>
        {(() => {
          let recurse = (items, result, level, parent) => {
              // Totals parciales de cada padre
              // let totals = {};
              // positions.forEach(position => totals[position] = reduce(items, (sum, n, key) => sum + parseFloat(n.row[position]),0));
              items.forEach((item, key) => {
                if (!!item.children) {
                  result.push(
                    <TableBranch
                      totals={totals}
                      level={level}
                      item={item}
                      display={
                        level > 0
                          ? !!this.state.displayes[parent]
                            ? this.state.displayes[parent].state
                            : false
                          : true
                      }
                      change={(code, display, childOf) => {
                        let newDisplayes = clone(this.state.displayes);
                        if (typeof newDisplayes[code] !== "object")
                          newDisplayes[code] = {};
                        newDisplayes[code].state = display;
                        if (typeof parent !== "undefined") {
                          if (
                            typeof newDisplayes[parent].children === "undefined"
                          )
                            newDisplayes[parent].children = [];
                          newDisplayes[parent].children.push(code);
                        }
                        if (
                          Array.isArray(newDisplayes[code].children) &&
                          !display
                        )
                          childrenHide(
                            newDisplayes[code].children,
                            newDisplayes
                          );
                        this.setState({ displayes: newDisplayes });
                        function childrenHide(children, displayes) {
                          children.forEach(elem => {
                            displayes[elem].state = false;
                            if (Array.isArray(displayes[elem].children))
                              childrenHide(displayes[elem].children, displayes);
                          });
                        }
                      }}
                      key={item.code}
                    />
                  );
                  recurse(item.children, result, level + 1, item.code);
                } else {
                  result.push(
                    <TableLeaf
                      totals={totals}
                      level={level}
                      item={item}
                      display={
                        !!this.state.displayes[parent]
                          ? this.state.displayes[parent].state
                          : level === 0
                      }
                      key={item.code}
                    />
                  );
                }
              });
            },
            draw = [];
          recurse(body, draw, 0);
          if (this.props.descriptor.totals) {
            let row = [];
            positions.map(position => (row[position] = totals[position]));
            for (let key = 0; key < row.length; key++) {
              if (row[key] === undefined) {
                row[key] = "";
              }
            }
            const descriptor = cloneDeep(this.props.descriptor);
            descriptor.columns.forEach(elem => {
              if (elem.type === "progress") {
                elem.type = "currency";
              }
            });

            // for (let i = 0; i < row.length; i++) {
            //   columns.push(
            //     <td
            //       key={i}
            //       data-metaType={this.props.descriptor.columns[i].type || (row[i] && 'currency')}
            //     >
            //       {row[i] && row[i].toLocaleString('es-ES', { minimumFractionDigits: 2 })}
            //     </td>
            //   );
            // }
            // const tr = <tr key="total">{columns}</tr>;
            draw.push(
              <tr key="total">
                <td className="icon" />
                {RowDraw(row, 0, descriptor)}
              </tr>
            );
          }
          return draw;
        })()}
      </tbody>
    );
  }

  static childContextTypes = {
    descriptor: PropTypes.object.isRequired
  };

  collapsible(data, code = 0) {
    let slashed = false,
      result = [];

    for (let i = data.length <= 5 ? data.length - 1 : 5; i >= 0; i--) {
      if (/[-]/.test(data[i][code])) slashed = true;
    }

    const organicos = /^([A-Za-z])(\w|\.)?(\d{3})?$/;
    const letra = /^([A-Za-z])(\d)(\d)/;

    data.forEach(function(item, index) {
      var cod = slashed
        ? item[code].split("-")
        : organicos.test(item[code])
          ? compact(organicos.exec(item[code]).slice(1))
          : letra.test(item[code])
            ? compact(letra.exec(item[code]).slice(1))
            : compact(/(\d)?(\d)?(\d)?(\d\d)?/.exec(item[code]).slice(-4));
      agroup(cod, result);

      function agroup(cod, result, i = 0) {
        if (i < cod.length) {
          if (!result[cod[i]]) result[cod[i]] = {};
          if (i == cod.length - 1) {
            result[cod[i]].code = item[code];
            result[cod[i]].row = item;
          } else {
            result[cod[i]].children = !!result[cod[i]].children
              ? result[cod[i]].children
              : [];
            result[cod[i]].children = agroup(
              cod,
              result[cod[i]].children,
              i + 1
            );
          }
        }
        return result;
      }
    });
    result = cleanArray(result);
    result.forEach(elem => {
      if (elem.children) moveEmptyChilds(elem.children, elem);
    });
    return result;

    function cleanArray(array) {
      const reindex = [];
      forOwn(array, (value, key) => {
        reindex.push(value);
      });
      array = compact(reindex);
      for (var i = 0; i < array.length; i++) {
        if (!!array[i].children) {
          array[i].children = cleanArray(array[i].children);
        }
      }
      return array;
    }

    function moveEmptyChilds(children, parent) {
      children.forEach(function(child) {
        if (
          typeof parent === "undefined" &&
          typeof child.children !== "undefined"
        )
          moveEmptyChilds(child.children, child);
        if (
          typeof child.row === "undefined" &&
          typeof child.children !== "undefined"
        ) {
          if (parent.moved) {
            parent.children = parent.children.concat(child.children);
          } else {
            parent.children = child.children;
            parent.moved = true;
          }
          moveEmptyChilds(parent.children, parent);
        } else if (
          typeof child.children !== "undefined" &&
          typeof child.row !== "undefined"
        ) {
          moveEmptyChilds(child.children, child);
        }
      });
    }
  }
}

export default TableTree;
