import React  from "react";
import PropTypes from "prop-types";
import getByCriteria from "./getByCriteria";
import { scaleOrdinal, schemeCategory10 } from "d3-scale";

const color = scaleOrdinal(schemeCategory10);
function formatNumber(text) {
  if (!isNaN(text)) return parseFloat(text);
  const european = /^[-]?\d{1,3}([.]\d{3})*([,]\d+)?$/;
  const number = /^[-]?\d+[.|,]?\d*$/;
  if (number.test(text)) {
    let num;
    if (european.test(text)) {
      num = text.replace(".", "").replace(",", ".");
    } else {
      num = text.replace(",", ".");
    }
    return parseFloat(num);
  }
  return NaN;
}

// function ColorLuminance(hex, lum) {
// 	hex = String(hex).replace(/[^0-9a-f]/gi, '');
// 	if (hex.length < 6) {
// 		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
// 	}
// 	lum = lum || 0;
// 	var rgb = "#", c, i;
// 	for (i = 0; i < 3; i++) {
// 		c = parseInt(hex.substr(i*2,2), 16);
// 		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
// 		rgb += ("00"+c).substr(c.length);
// 	}
//
// 	return rgb;
// }

function progressPercentage(value, total) {
  const percentage = (value / total * 100).toFixed(2);
  return `${percentage}%`;
}

export default function RowDraw(row, rowIndex, descriptor, totals) {
  const collapse = descriptor && descriptor.collapse;
  let clr;
  let progress;
  if (descriptor && !isNaN(collapse)) {
    clr = color(row[collapse].charAt(0));
  } else {
    clr = false;
  }
  const result = row.map((column, columnIndex, array) => {
    let negative = false;
    const columnMetadata =
      !!descriptor && !!descriptor.columns && !!descriptor.columns[columnIndex]
        ? descriptor.columns[columnIndex]
        : {
            type: ""
          };
    if (columnMetadata.isHidden) {
      columnMetadata.type = "hidden";
    }
    switch (columnMetadata.type) {
      case "link":
        return (
          <td
            className={columnMetadata.style}
            data-metaType={columnMetadata.type}
            key={`td-${columnIndex}`}
          >
            <a href={column}>
              <i className="large linkify icon" />
            </a>
          </td>
        );
        break;
      case "iconlink":
        return (
          <td data-metaType={columnMetadata.type} key={`td-${columnIndex}`}>
            <a href={column}>
              <i className={columnMetadata.style} />
            </a>
          </td>
        );
        break;
      case "download":
        return (
          <td
            className={columnMetadata.style}
            data-metaType={columnMetadata.type}
            key={`td-${columnIndex}`}
          >
            {column && (
              <a href={column} download>
                <i className="fa fa-download fa-lg" />
              </a>
            )}
          </td>
        );
        break;
      case "info":
        return (
          <td
            className={columnMetadata.style}
            data-metaType={columnMetadata.type}
            key={`td-${columnIndex}`}
          >
            <a href={column}>
              <i className="large info icon" />
            </a>
          </td>
        );
        break;
      case "progress":
        const p = progressPercentage(column, totals[columnIndex]);
        progress = {
          value: p,
          color: clr,
          position: columnIndex + 1
        };
      case "currency":
      case "percentage":
        if (isNaN(formatNumber(column))) {
          return <td />;
          break;
        }
        negative = formatNumber(column) < 0;
      case "number":
        const number = formatNumber(column);
        return (
          <td
            className={`${negative
              ? "negative"
              : "positive"} ${columnMetadata.style}`}
            data-metaType={columnMetadata.type}
            key={`td-${columnIndex}`}
          >
            {number.toLocaleString("es", { minimumFractionDigits: 2 })}
          </td>
        );
        break;
      // return (
      // 	<td style={{
      // 	}} className={columnMetadata.style} data-metaType={columnMetadata.type} key={`td-${columnIndex}`}>
      // 		{formatNumber(column).toLocaleString('es-ES', {minimumFractionDigits: 2})}
      // 	</td>
      // );
      // break;
      default:
        return (
          <td
            className={columnMetadata.style}
            data-metaType={columnMetadata.type}
            key={`td-${columnIndex}`}
          >
            {column}
          </td>
        );
    }
  });
  if (progress) {
    result.splice(
      progress.position,
      0,
      <td
        key="percentage"
        data-bocadillo={progress.value}
        style={{
          background: `linear-gradient(to right,${progress.color} ${progress.value},white 0)`,
          width: "200px"
        }}
      />
    );
  }
  return result;
}

export { formatNumber };
