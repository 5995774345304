import React  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import localize from "../util/localize";
import ColumnFilter from "../components/ColumnFilter";
import { iconClass } from "../util/vars";

class Header extends React.Component {
  nextPosition(position) {
    const positions = ["neutral", "asc", "desc"];
    const number =
      positions.indexOf(position) + 1 >= 3
        ? 0
        : positions.indexOf(position) + 1;
    return positions[number];
  }

  icon(type, criteria) {
    return `fa fa-sort-${type === "string" ? "alpha" : "numeric"}-${iconClass[
      criteria
    ]}`;
  }

  render() {
    return (
      <div className="header">
        <div className="cell icon" />{" "}
        {this.props.headers.map((item, index) =>
          <div
            key={index}
            data-metatype={item.type}
            className={"cell " + item.style}
          >
            {item.isFilterable ? <ColumnFilter id={index} /> : null}
            {localize(item.title ? item.title : item, this.context.locale)}
            {item.isSortable
              ? <i
                  onClick={() =>
                    this.props.updateCriteria(
                      index,
                      this.nextPosition(this.props.criteria[index] || "neutral")
                    )}
                  className={`sort ${this.props.criteria[index] ||
                    "neutral"} ${this.icon(
                    item.type,
                    this.props.criteria[index]
                  )}`}
                />
              : null}
          </div>
        )}
      </div>
    );
  }

  static contextTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default connect(
  state => ({
    headers: state.data.rows[0].row.map(
      item =>
        state.data.columns
          ? state.data.columns[item.index]
          : state.data.headings
    ),
    criteria: state.criteria
  }),
  dispatch => ({
    updateCriteria: (index, direction) =>
      dispatch({ type: "UPDATE_CRITERIA", index, direction })
  })
)(Header);
