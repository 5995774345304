import React  from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduce, without } from "lodash";
import localize from "../util/localize";
import ColumnFilter from "../components/ColumnFilter";
import ColumnGroup from "../components/ColumnGroup";
import { iconClass } from "../util/vars";

class Header extends React.Component {
  nextPosition(position) {
    const positions = ["neutral", "asc", "desc"];
    const number =
      positions.indexOf(position) + 1 >= 3
        ? 0
        : positions.indexOf(position) + 1;
    return positions[number];
  }

  icon(type, criteria) {
    return `fa fa-sort-${type === "string" ? "alpha" : "numeric"}-${iconClass[
      criteria
    ]}`;
  }

  render() {
    const { locale } = this.context;
    return (
      <div className="header">
        {this.props.grouped
          ? <div
              className="cell icon"
              style={{
                order: -2
              }}
            />
          : null}
        <div className={`cell icon ${this.props.grouped ? "num" : ""}`}>
          {this.props.grouped
            ? localize({ es: "cuenta", ca: "compte" }, this.context.locale)
            : ""}
          {this.props.grouped
            ? <i
                onClick={() =>
                  this.props.updateCriteria(
                    "count",
                    this.nextPosition(this.props.criteria["count"] || "neutral")
                  )}
                className={`sort ${this.props.criteria["count"] ||
                  "neutral"} ${this.icon(
                  "numeric",
                  this.props.criteria["count"]
                )}`}
              />
            : null}
        </div>
        {this.props.headers.map((item, index) =>
          <div
            data-group={this.props.groups[item.index] ? "yes" : "no"}
            key={index}
            data-metatype={item.value.type}
            className={"cell " + item.value.style}
          >
            {item.value.isFilterable && !item.value.isGroupable
              ? <ColumnFilter id={index} />
              : null}
            {item.value.isGroupable && item.value.isGroupable !== "fixed"
              ? <ColumnGroup id={item.index} />
              : null}
            {localize(
              item.value.title ? item.value.title : item.value,
              this.context.locale
            )}
            {item.value.agregateFunc ? ` (${item.value.agregateFunc})` : ""}
            {item.value.isSortable
              ? <i
                  onClick={() =>
                    this.props.updateCriteria(
                      index,
                      this.nextPosition(this.props.criteria[index] || "neutral")
                    )}
                  className={`sort ${this.props.criteria[index] ||
                    "neutral"} ${this.icon(
                    item.value.type,
                    this.props.criteria[index]
                  )}`}
                />
              : null}
          </div>
        )}
      </div>
    );
  }

  static contextTypes = {
    locale: PropTypes.string.isRequired
  };
}

export default connect(
  state => {
    const row = state.data.rows[0].row
    return {
      headers: row.map(item => ({
        index: item.index,
        value: state.data.columns
          ? state.data.columns[item.index]
          : state.data.headings
      })),
      criteria: state.criteria,
      groups: state.groups,
      grouped: reduce(state.groups, (test, value) => test || value)
    };
  },
  dispatch => ({
    updateCriteria: (index, direction) =>
      dispatch({ type: "UPDATE_CRITERIA", index, direction })
    /*     changeGrouped: group => dispatch({ type: 'GROUPS_UPDATE', group }) */
  })
)(Header);
