import React from "react";
import PropTypes from "prop-types";
import { flatten } from "lodash";
import { connect } from "react-redux";
import formatter from "../util/format";
import localize from "../util/localize";
import LinkPopup from "../components/LinkPopup";

class Row extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { columns, pdf, row, id, isSelected } = this.props;
    const { event } = this.context;
    const fileExtension = new RegExp("\\.(\\w{2,5})$");
    return (
      <div
        className={`row${isSelected ? " selected" : ""}`}
        onClick={e => event.onClick({ row, id })}
      >
        <div className="cells">
          {row.map(item => (
            <div
              key={item.index}
              data-label={localize(
                this.props.headers[item.index],
                this.context.locale
              )}
              data-metatype={columns[item.index].type}
              className={"cell " + columns[item.index].style}
            >
              {columns
                ? formatter(item.value, columns[item.index])
                : item.value}
            </div>
          ))}
          {pdf ? (
            pdf.map((item, index) => (
              <div key={item.index} className="cell icon download">
                {item.value &&
                  (this.context.isDirectLink ? (
                    <a
                      target="_blank"
                      href={`${this.context.filePath}/${
                        fileExtension.test(item.value)
                          ? item.value
                          : item.value + ".pdf"
                      }`}
                    />
                  ) : (
                    <LinkPopup
                      href={`${this.context.filePath}${
                        fileExtension.test(item.value)
                          ? item.value
                          : item.value + ".pdf"
                      }`}
                    />
                  ))}
              </div>
            ))
          ) : (
            <div className="cell icon download" />
          )}
        </div>
      </div>
    );
  }

  static PropTypes = {
    row: PropTypes.array.isRequired,
    defaultName: PropTypes.array.isRequired,
    pdf: PropTypes.array.isRequired
  };

  static contextTypes = {
    filePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    isDirectLink: PropTypes.bool.isRequired,
    event: PropTypes.object
  };
}

export default connect(state => ({ columns: state.data.columns }))(Row);
