import { combineReducers, compose, createStore } from "redux";
import { reducer as searchReducer, reduxSearch } from "redux-search";
import { cloneDeep as clone } from "lodash";

function initialize(initial) {
  function reducer(state = {}, action) {
    switch (action.type) {
      default:
        return state;
    }
  }

  function filter(state = {}, action) {
    switch (action.type) {
      case "FILTER_UPDATE":
        const newState = clone(state);
        newState[action.id] =
          action.filter === "none" ? undefined : action.filter;
        return newState;
      default:
        return state;
    }
  }

  function criteria(state = {}, action) {
    switch (action.type) {
      case "UPDATE_CRITERIA":
        const newState = clone(state);
        newState[action.index] = action.direction;
        return newState;
      default:
        return state;
    }
  }

  function paginator(
    state = {
      current: 1,
      pageSize: 10
    },
    action
  ) {
    const newState = clone(state);
    switch (action.type) {
      case "CHANGE_PAGE":
        newState.current = action.page;
        return newState;
      case "PAGE_SIZE":
        newState.pageSize = action.size;
        return newState;
      default:
        return state;
    }
  }
  const rootReducer = combineReducers({
    search: searchReducer,
    data: reducer,
    criteria,
    paginator,
    filter
  });

  const enhancer = compose(
    reduxSearch({
      resourceIndexes: {
        rows: ({ resources, indexDocument, state }) => {
          resources.forEach(({ row, id }, index) => {
            row.forEach(cell => indexDocument(index, cell.value.toString()));
          });
        }
      },
      resourceSelector: (resourceName, state) => state.data[resourceName]
    })
  );

  const store = createStore(
    rootReducer,
    {
      data: initial
    },
    enhancer
  );
  return store;
}

export default initialize;
