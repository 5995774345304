import React, {PropTypes} from 'react';
import RowDraw from '../util/RowDraw';
class TableLeaf extends React.Component {
		shouldComponentUpdate(nextProps, nextState) {
			return this.props.display !== nextProps.display;
		}

    render() {
        let item = this.props.item;
				if (this.props.display) {
        return (
            <tr data-level={this.props.level} className='leaf'>
                <td style={{paddingLeft:`${15*(this.props.level+1)}px`}}/>
                {RowDraw(item.row, item.rowIndex, this.context.descriptor, this.props.totals)}
            </tr>
        )
			} else {
				return null;
			}
    }

    static contextTypes = {
        descriptor: PropTypes.object.isRequired
    }
}

export default TableLeaf;
